.client-type-select {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-top: 20px;
    margin-bottom: 20px;

    .form-group {
        display: flex;
        flex-direction: row-reverse;
        gap: 20px;
    }
}
