@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "./theme.scss";
@import "./components/form.scss";
@import "./components/clientTypeSelect.scss";
@import "./shared.scss";
@import "./mixins/breakpoints.scss";

.app {
  font-family: "Roboto", sans-serif;
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 425px;
  margin: auto;
  &__info {
    margin-bottom: 20px;
    color: gray;
    &__error {
      color: tomato;
    }
  }
  &__header {
    display: flex;
    &__logo {
      max-width: 100px;
      & img {
        width: 100%;
      }
    }
    &__text {
      font-size: 22px;
    }
  }
  &__result {
    display: flex;
    flex-direction: column;
    &__header {
      font-feature-settings: 18px;
    }
    &__row {
      color: $font-gray;
    }
  }
}

.preloader {
  color: $font-gray;
}

.error-message {
  color: tomato;
}
