.request-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  &__pair {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  &__cell {
    display: flex;
    flex-direction: column;
  }
  &__inputs {
    display: flex;
    flex-wrap: nowrap;
  }
  &__input {
    border: none;
    outline: none;
    border-bottom: 1px solid lightgray;
    max-width: 60px;
    font-size: 16px;
    background: transparent;
    margin-right: 10px;
    &:focus {
      outline: none;
    }
    &__label {
      color: lightgray;
    }
    &__dots {
      position: absolute;
      right: 2px;
      font-weight: 600;
    }
  }

  &__submit {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background: white;
      color: $main-color;
      border: 1px solid $main-color;
    }
  }

  &__input-group {
    position: relative;
  }
}
