.container {
  margin: auto;
  padding: 20px 20px;
}

.fullscreen {
  height: 100vh;
  width: 100%;
}

.fullwidth {
  width: 100%;
}

.fullheight {
  height: 100%;
}

.flex {
  display: flex;
}

.flex-centered {
  align-items: center;
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.button {
  text-align: center;
  background: #3493a2;
  border: 1px solid white;
  color: white;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}
